import { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import { TSchedule } from "../../app/models/schedule";
import { observer } from "mobx-react-lite";
import { id } from "date-fns/locale";
import { Box, Grid } from "@mui/material";
import { GRID_HEADER } from "../../app/config/enum";
import { DataContext } from "../../App";
import { primaryBlue } from "../../utils/color";

type TScheduleDataGridProps = {
  setSelectedSchedule?: Dispatch<SetStateAction<TSchedule | undefined>>;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  isPrint?: boolean;
};

function ScheduleDataGrid({ isPrint = false, setSelectedSchedule, setOpen }: TScheduleDataGridProps) {
  const { matches600, matches900, matches1200 } = useContext(DataContext);
  const {
    scheduleStore: { allSchedule },
  } = useStore();

  const fontSizeResponsive = useMemo(() => {
    if (matches600) return "7px";
    if (matches900) return "9px";
    if (matches1200) return "10px";
    return "10px";
  }, [matches1200, matches600, matches900]);

  const Header = () => {
    return (
      <>
        {GRID_HEADER.map((item, index) => {
          return (
            <Grid item xs={index === 1 ? 2 : index === 8 ? 1.5 : 1}>
              <Box
                sx={{
                  textAlign: "center",
                  alignItems: "center",
                  borderTopLeftRadius: index === 0 ? "80px" : "",
                  borderBottomLeftRadius: index === 0 ? "80px" : "",
                  borderTopRightRadius: index === GRID_HEADER.length - 1 ? "80px" : "",
                  borderBottomRightRadius: index === GRID_HEADER.length - 1 ? "80px" : "",
                  py: "6px",
                  color: "white",
                  pb: "12px",
                  backgroundColor: primaryBlue,
                  borderWidth: "1px",
                  fontWeight: "bold",
                  fontSize: fontSizeResponsive,
                }}
              >
                {item}
              </Box>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <Box sx={{ alignItems: "center", width: "100%" }}>
      <Grid sx={{ fontSize: fontSizeResponsive, textAlign: "center", rowGap: "3px", color: primaryBlue }} container>
        <Header />
        {allSchedule?.map((item: TSchedule, index: number) => (
          <>
            <Grid item xs={1}>
              {item.dateHijriyah.slice(0, 2)}
            </Grid>
            <Grid item xs={2}>
              {format(new Date(item?.date || "2024-03-12"), "dd MMM yyyy", { locale: id })}
            </Grid>
            <Grid item xs={1} sx={{ fontWeight: 800, color: primaryBlue }}>
              {item.imsak}
            </Grid>
            <Grid item xs={1}>
              {item.subuh}
            </Grid>
            <Grid item xs={1}>
              {item.terbit}
            </Grid>
            <Grid item xs={1}>
              {item.duha}
            </Grid>
            <Grid item xs={1}>
              {item.zuhur}
            </Grid>
            <Grid item xs={1}>
              {item.ashar}
            </Grid>
            <Grid item xs={1.5} sx={{ fontWeight: 800, color: primaryBlue }}>
              {item.magrib}
            </Grid>
            <Grid item xs={1}>
              {item.isya}
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
}

export default observer(ScheduleDataGrid);
