export enum FileType {
  Image = "image/*",
  Audio = "audio/*",
  Vieo = "video/*",
  Document = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint",
}

export enum Sex {
  Male = "Laki - Laki",
  Female = "Perempuan",
}

export const POST_COUNTER_TYPE = {
  HARIAN: "HARIAN",
  BULANAN: "BULANAN",
};

export const POST_DEVICE_TYPE = {
  MOBILE: "Mobile",
  DESKTOP: "Desktop",
};

export enum Roles {
  Superadmin = "Superadmin",
  Admin = "Admin",
}

export const RAMADHAN_DATE = [
  {
    masehi: "",
    hijriah: "",
  },
];

export const PRIVACY_POLICY = [
  {
    title: "Log Files",
    description:
      "Jadwal Imsakiyah BTN 2024 follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users movement on the website, and gathering demographic information. Our Privacy Policy was created with the help of the Privacy Policy Generator.",
  },
  {
    title: "Privacy Policies",
    description:
      "You may consult this list to find the Privacy Policy for each of the advertising partners of Jadwal Imsakiyah BTN 2024. Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Jadwal Imsakiyah BTN 2024, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit. Note that Jadwal Imsakiyah BTN 2024 has no access to or control over these cookies that are used by third-party advertisers.",
  },
  {
    title: "Third Party Privacy Policies",
    description:
      "Jadwal Imsakiyah BTN 2024's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?",
  },
  {
    title: "Children's Information",
    description:
      "Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity. Jadwal Imsakiyah BTN 2024 does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.",
  },
  {
    title: "Online Privacy Policy Only",
    description:
      "This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Jadwal Imsakiyah BTN 2024. This policy is not applicable to any information collected offline or via channels other than this website.",
  },
  {
    title: "Consent",
    description: "By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.",
  },
];

export const TERM_AND_CONDITIONS = [
  {
    title: "Cookies",
    description:
      "We employ the use of cookies. By accessing Jadwal Imsakiyah BTN 2024, you agreed to use cookies in agreement with the Jadwal Imsakiyah BTN 2024's Privacy Policy. Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.",
  },
  {
    title: "License",
    description:
      "Unless otherwise stated, Jadwal Imsakiyah BTN 2024 and/or its licensors own the intellectual property rights for all material on Jadwal Imsakiyah BTN 2024. All intellectual property rights are reserved. You may access this from Jadwal Imsakiyah BTN 2024 for your own personal use subjected to restrictions set in these terms and conditions. You must not: Republish material from Jadwal Imsakiyah BTN 2024 Sell, rent or sub-license material from Jadwal Imsakiyah BTN 2024 Reproduce, duplicate or copy material from Jadwal Imsakiyah BTN 2024 Redistribute content from Jadwal Imsakiyah BTN 2024",
  },
  {
    title: "Hyperlinking to our Content",
    description:
      "The following organizations may link to our Website without prior written approval: Government agencies Search engines News organizations Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site. These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site. We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Jadwal Imsakiyah BTN 2024; and (d) the link is in the context of general resource information. These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site. If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Jadwal Imsakiyah BTN 2024. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.",
  },
  {
    title: "iFrames",
    description:
      "Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website..",
  },
  {
    title: "Content Liability",
    description:
      "We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.",
  },
  {
    title: "Reservation of Rights",
    description:
      "We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.",
  },
  {
    title: "Removal of links from our website",
    description:
      "If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly. We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date..",
  },
  {
    title: "Disclaimer",
    description:
      "To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will: limit or exclude our or your liability for death or personal injury limit or exclude our or your liability for fraud or fraudulent misrepresentation limit any of our or your liabilities in any way that is not permitted under applicable law; orexclude any of our or your liabilities that may not be excluded under applicable law. The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty. As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.",
  },
];

export const DKI = "DKI Jakarta";
export const RAMADHAN_DAY = 30;
export const RAMADHAN_FIRST_DAY = "2024-03-12";
export const ONE_DAY_BEFORE_RAMADHAN = "2024-03-11T00:00:00.000Z";
export const GRID_HEADER = ["No", "Tanggal", "Imsak", "Subuh", "Terbit", "Duha", "Zuhur", "Asar", "Magrib", "Isya"];
export const SCHEDULE_OPTIONS = ["imsak", "subuh", "terbit", "duha", "zuhur", "ashar", "magrib", "isya"];
export const GOOGLE_SCOPES = "https://www.googleapis.com/auth/calendar";
export const FAVOURITE_CITIES = ["DKI Jakarta", "Bandung", "Surabaya", "Denpasar", "Medan", "Makassar", "Batam", "Palembang", "Pekanbaru", "Malang"];

//Notes: yang dikirim di db keys nya
export function enumToArray(obj: any) {
  return Object.keys(obj).map((value) => ({ value, text: obj[value] }));
}

export const enumMap = <T>(obj: {}, callbackFn: (key: string, value: string, index: number) => T): T[] => {
  // const enumValues: string[] = Object.values(obj);
  return Object.keys(obj).map<T>((k, i) => callbackFn(k, k, i));
};
