import { Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { createContext, useEffect } from "react";
import { useStore } from "./app/stores/store";
import Home from "./features/home/Home";
import UserPage from "./features/user/UserPage";
import Tester from "./features/tester/Tester";
import ServerError from "./features/errors/ServerError";
import NotFound from "./features/errors/NotFound";
import RkdModal from "./app/component/helper/RkdModal";
import RkdSnackbar from "./app/component/helper/RkdSnackbar";
import './App.css'
import RkdDialog from "./app/component/helper/RkdDialog";
import RkdBackdrop from "./app/component/helper/RkdBackdrop";
import RouteAuth from "./app/component/helper/RouteAuth";
import AccessDenied from "./features/errors/AccessDenied";
import { Roles } from "./app/config/enum";
import { PageFormPage } from "./features/page/PageFormPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useMediaQuery } from "@mui/material";
import PagePage from "./features/page/PagePage";
import PrivacyPolicy from "./features/home/PrivacyPolicy";
import TermConditions from "./features/home/TermConditions";
import MonthScheduleNewDesign from "./features/home/Export/MonthlyNewDesign/MonthScheduleNewDesign";
import DayScheduleNewDesign from "./features/home/Export/DailyNewDesign/DaySchedule";
import SecretAdmin from "./features/home/SecretAdmin";

export const DataContext = createContext<any>("");

const App = () => {
  const { commonStore, accountStore } = useStore();

  const matches1280 = useMediaQuery("(max-width: 1280px)");
  const matches1200 = useMediaQuery("(max-width: 1200px)");
  const matches1100 = useMediaQuery("(max-width: 1100px)");
  const matches1023 = useMediaQuery("(max-width: 1023px)");
  const matches984 = useMediaQuery("(max-width: 984px)");
  const matches900 = useMediaQuery("(max-width: 900px)");
  const matches800 = useMediaQuery("(max-width: 800px)");
  const matches700 = useMediaQuery("(max-width: 700px)");
  const matches600 = useMediaQuery("(max-width: 600px)");
  const matches500 = useMediaQuery("(max-width: 500px)");
  const matches480 = useMediaQuery("(max-width: 480px)");
  const matches446 = useMediaQuery("(max-width: 446px)");
  const matches400 = useMediaQuery("(max-width: 400px)");
  const matches300 = useMediaQuery("(max-width: 300px)");
  const matches385 = useMediaQuery("(max-width: 385px)");
  const matches294 = useMediaQuery("(max-width: 294px)");
  const matches222 = useMediaQuery("(max-width: 222px)");

  const mH800 = useMediaQuery("(max-height: 800px)");

  useEffect(() => {
    if (commonStore.token) {
      commonStore.setAppLoaded();
      // accountStore.getUser().finally(() => );
    } else {
      commonStore.setAppLoaded();
    }
  }, [accountStore, commonStore]);

  if (!commonStore.appLoaded) return <RkdBackdrop />;

  let CLIENT_ID = "751502265375-r9brrpugs68repmgrj3u6du1j2l0l30k.apps.googleusercontent.com";

  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <DataContext.Provider
        value={{
          mH800,
          matches222,
          matches294,
          matches446,
          matches480,
          matches1280,
          matches1200,
          matches1023,
          matches984,
          matches900,
          matches600,
          matches800,
          matches700,
          matches400,
          matches300,
          matches1100,
          matches500,
          matches385,
        }}
      >
        <RkdModal />
        <RkdDialog />
        <RkdSnackbar />
        <Routes>
          <Route>
            <Route index={true} element={<Home></Home>} />
            <Route path="/dayschedule/:cityId/:date" element={<DayScheduleNewDesign />} />
            {/* <Route path="/monthschedule/:cityId" element={<MonthSchedule />} /> */}
            <Route path="/monthschedule/:cityId" element={<MonthScheduleNewDesign />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/term-conditions" element={<TermConditions />} />
            <Route path="/secret-admin" element={<SecretAdmin />} />
            <Route path="/page" element={<PagePage />} />
            <Route path="/page/form" element={<PageFormPage />} />
            <Route element={<RouteAuth roles={[Roles.Superadmin]} />}>
              <Route path="/users/*" element={<UserPage />} />
              <Route path="/tester" element={<Tester />} />
            </Route>
            {/* Error */}
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="/server-error" element={<ServerError />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </DataContext.Provider>
    </GoogleOAuthProvider>
  );
};

export default observer(App);
