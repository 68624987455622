import { Box } from "@mui/material";

interface ImageProps {
  image: string;
  alt: string;
}

export const Image = ({ image, alt }: ImageProps) => {
  return (
    <Box>Image</Box>
  )
}

Image.displayName = 'Image'