import { Box, Grid } from "@mui/material";
import { useContext, useMemo } from "react";
import { DataContext } from "../../../App";
import { backgroundMain, primaryBlue, primaryRed } from "../../../utils/color";

export default function Header() {
  const { matches1023, matches500, matches300, mH800 } = useContext(DataContext);

  const fontSizeResponsive = useMemo(() => {
    if (matches300) return "11px";
    if (matches500) return "14px";
    return "18px";
  }, [matches300, matches500]);

  const fontSizeDesc = useMemo(() => {
    if (matches300) return "9px";
    if (matches500) return "10px";
    return "11px";
  }, [matches300, matches500]);

  const paddingHeader = useMemo(() => {
    if (mH800) return "0px";
    return "60px";
  }, [mH800]);

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Grid sx={{ backgroundColor: backgroundMain, width: matches1023 ? "100%" : "80%" }} container>
        <img style={{ objectFit: "cover" }} src="/assets/new-design/top-home.png" alt="bumn" width="100%" height="140px" />
      </Grid>
    </Box>
  );
}
