import { Box } from "@mui/material";
import Schedule from "./Schedule";
import Footer from "../component/home/Footer";
import Header from "../component/home/Header";

export default function Home() {
  return (
    <Box>
      {/* <Header /> */}
      <Schedule />
      <Footer />
    </Box>
  );
}
