import React, { useContext, useMemo, useState } from "react";
import { Box, Button } from "@mui/material";
import html2canvas from "html2canvas";
import { FileDownloadOutlined } from "@mui/icons-material";
import { ContentCopy, HomeRounded } from "@mui/icons-material";
import { DataContext } from "../../../App";
import { primaryBlue, primaryRed, primaryYellow } from "../../../utils/color";
import { useStore } from "../../../app/stores/store";
import { useParams } from "react-router-dom";
import { POST_COUNTER_TYPE, POST_DEVICE_TYPE } from "../../../app/config/enum";
import { isMobile } from "react-device-detect";
import { format } from "date-fns";

export default function ExportFooter() {
  const { counterStore } = useStore();
  const { matches600, matches385, matches900, matches1200 } = useContext(DataContext);
  const [copyLink, setCopyLink] = useState(false);

  const { date } = useParams();

  const widthContainer = useMemo(() => {
    if (matches385) return "60%";
    if (matches600) return "50%";
    if (matches900) return "40%";
    if (matches1200) return "30%";
    return "20%";
  }, [matches1200, matches385, matches600, matches900]);

  const handleImageDownload = async () => {
    const element = document.getElementById("print"),
      canvas = await html2canvas(element!, { scale: 4 }),
      data = canvas.toDataURL("image/png"),
      link = document.createElement("a");

    link.href = data;
    link.download = "BTN - JADWAL IMSAKIYAH RAMADHAN 1445H.png";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      sx={{
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        columnGap: "6px",
        py: "12px",
        px: "24px",
        position: "fixed",
        bottom: "0px",
        width: "100%",
      }}
    >
      <Box sx={{ width: widthContainer }}>
        <Button
          onClick={() => {
            handleImageDownload();
            const payload = {
              deviceType: isMobile ? POST_DEVICE_TYPE.MOBILE : POST_DEVICE_TYPE.DESKTOP,
              date: format(Date.now(), "yyyy-MM-dd"),
              type: !!date ? POST_COUNTER_TYPE.HARIAN : POST_COUNTER_TYPE.BULANAN,
            };
            counterStore.addDownloadCounter(payload);
          }}
          fullWidth
          sx={{ borderRadius: "24px", textTransform: "none", backgroundColor: primaryBlue }}
          variant="contained"
          startIcon={<FileDownloadOutlined />}
        >
          Download
        </Button>
      </Box>
      <Box
        onClick={() => {
          setCopyLink((prev) => !prev);
          navigator.clipboard.writeText(window.location.href);
        }}
        sx={{ color: primaryRed, cursor: "pointer", borderColor: copyLink ? "" : primaryRed, borderWidth: "3px", borderRadius: "999px", p: "4px" }}
      >
        {copyLink ? "Copied" : <ContentCopy sx={{ color: primaryRed }} />}
      </Box>
      <Box sx={{ cursor: "pointer", borderColor: primaryYellow, borderWidth: "3px", borderRadius: "999px", p: "4px" }}>
        <a href="/" rel="noreferrer">
          <HomeRounded sx={{ color: primaryYellow }} />
        </a>
      </Box>
    </Box>
  );
}
