import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import UserList from './UserList';

function UserPage() {
  return (
    <>      
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <UserList />
        </Grid>
      </Grid>
    </>
  );
}

export default observer(UserPage);
