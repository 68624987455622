import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { TCounterPayload, TCounterResponse } from "../models/counter";

export default class CounterStore {
  downloadCounter: TCounterResponse | undefined = undefined;
  loadingDownloadCounter = false;

  constructor() {
    makeAutoObservable(this);
  }

  getDownloadCounter = async () => {
    this.loadingDownloadCounter = true;

    try {
      const res = await agent.Counter.getDownloadCounter();

      runInAction(() => (this.downloadCounter = res));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDownloadCounter = false));
    }
  };

  addDownloadCounter = async (payload: TCounterPayload) => {
    this.loadingDownloadCounter = true;

    try {
      await agent.Counter.addDownloadCounter(payload);
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingDownloadCounter = false));
    }
  };
}
