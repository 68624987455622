import React, { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { Box, CircularProgress, Grid } from "@mui/material";
import { StyledDataGrid } from "../../app/component/StyledDataGrid";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";

function SecretAdmin() {
  const { counterStore } = useStore();

  useEffect(() => {
    counterStore.getDownloadCounter();
  }, []);

  return (
    <Box sx={{ mt: "24px", mx: "48px" }}>
      <Box sx={{ textAlign: "center" }}>
        <Box sx={{ fontWeight: "bold", fotnSize: "48px" }}>Download Counter</Box>
      </Box>

      {counterStore.loadingDownloadCounter ? (
        <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", mt: "24px" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ mt: "24px" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold", mb: "24px" }}>Jadwal Harian</Box>
              <StyledDataGrid
                hideFooterPagination
                autoHeight
                getRowId={(row) => row.date + row.total}
                loading={counterStore.loadingDownloadCounter}
                rows={counterStore.downloadCounter?.downloadHarian ?? []}
                columns={[
                  {
                    field: "date",
                    headerName: "Access Date",
                    flex: 1,
                    renderCell: (params) => format(new Date(params.row.date), "dd MMMM yyyy"),
                  },
                  {
                    field: "count",
                    headerName: "Total",
                    flex: 1,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold", mb: "24px" }}>Jadwal Bulanan</Box>
              <StyledDataGrid
                hideFooterPagination
                autoHeight
                getRowId={(row) => row.date + row.total}
                loading={counterStore.loadingDownloadCounter}
                rows={counterStore.downloadCounter?.downloadBulanan ?? []}
                columns={[
                  {
                    field: "date",
                    headerName: "Access Date",
                    flex: 1,
                    renderCell: (params) => format(new Date(params.row.date), "dd MMMM yyyy"),
                  },
                  {
                    field: "count",
                    headerName: "Total",
                    flex: 1,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ textAlign: "center", fontWeight: "bold", mb: "24px" }}>Total Download Berdasarkan Device</Box>
              <StyledDataGrid
                hideFooterPagination
                autoHeight
                getRowId={(row) => row.type}
                loading={counterStore.loadingDownloadCounter}
                rows={counterStore.downloadCounter?.deviceType ?? []}
                columns={[
                  {
                    field: "type",
                    headerName: "Type",
                    flex: 1,
                  },
                  {
                    field: "count",
                    headerName: "Total",
                    flex: 1,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <Box>
                <span style={{ fontWeight: "bold" }}>Total User Download Jadwal Harian: </span>
                {counterStore.downloadCounter?.downloadHarian.reduce((partialSum, a) => partialSum + a.count, 0)}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <span style={{ fontWeight: "bold" }}>Total User Download Jadwal Bulanan: </span>
                {counterStore.downloadCounter?.downloadBulanan.reduce((partialSum, a) => partialSum + a.count, 0)}
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <span style={{ fontWeight: "bold" }}>Total User Download Berdasarkan Device: </span>
                {counterStore.downloadCounter?.deviceType.reduce((partialSum, a) => partialSum + a.count, 0)}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default observer(SecretAdmin);
