import React, { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { TSelectedCity } from "./Schedule";
import { FileDownloadOutlined } from "@mui/icons-material/";
import { SCHEDULE_OPTIONS } from "../../app/config/enum";
import { TSchedule } from "../../app/models/schedule";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import AddToCalendarModal from "../component/home/AddToCalendarModal";
import { DataContext } from "../../App";
import { primaryBlue, primaryRed, secondaryBlue, secondaryRed } from "../../utils/color";

type TScheduleDetailProps = {
  selectedDate: string;
  selectedCity: TSelectedCity | null;
};

function ScheduleDetail({ selectedCity, selectedDate }: TScheduleDetailProps) {
  const { matches1023, matches500, matches600 } = useContext(DataContext);
  const {
    cityStore: { defaultCity, getCityList, loading },
    scheduleStore: { getDailySchedule, dailySchedule, loadingGetDailySchedule },
  } = useStore();
  const [open, setOpen] = useState(false);
  const [isAll, setIsAll] = useState(false);

  useEffect(() => {
    if (!selectedCity || !selectedDate) return;

    getDailySchedule(selectedCity.value, selectedDate);
  }, [defaultCity, getCityList, getDailySchedule, selectedCity, selectedDate]);

  return (
    <Box
      sx={{
        display: "flex",
        width: matches1023 ? "100%" : "80%",
        justifyContent: "center",
        backgroundAttachment: "inherit",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundImage: `url('/assets/new-design/bg.png')`,
        // backgroundImage: `url('/assets/new-design/bg-home.png')`,
        // borderRadius: "12px",
        boxShadow: "0 10px 8px rgb(0 0 0 / 0.04), 0 4px 3px rgb(0 0 0 / 0.1)",
      }}
    >
      <Grid
        sx={{
          width: matches1023 ? "100%" : "60%",
          my: "56px",
          mx: "24px",
        }}
        container
      >
        {loadingGetDailySchedule || loading ? (
          <Grid xs={24} sx={{ my: "48px", textAlign: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ color: primaryBlue, textAlign: "center" }}>
              <Box sx={{ color: primaryBlue, mb: "36px" }}>
                <Box sx={{ textTransform: "uppercase", fontSize: "18px" }}>{dailySchedule?.cityName}</Box>
                <Box sx={{ fontSize: "24px" }}>{format(new Date(dailySchedule?.date || "2024-03-12"), "dd MMMM yyyy", { locale: id })}</Box>
                <Box sx={{ fontSize: "18px", fontWeight: 700 }}>{dailySchedule?.dateHijriyah}</Box>
              </Box>
              {matches600 ? (
                ""
              ) : (
                <>
                  <Box sx={{ color: primaryBlue, fontSize: "12px", mb: "48px" }}>
                    <span style={{ color: primaryRed }}>Catatan:</span> Penentuan awal dan akhir Ramadan mengikuti keputusan Pemerintah melalui Sidang
                    Isbat Kementerian Agama RI
                  </Box>
                  <Button
                    sx={{ borderRadius: "24px", mb: "24px", textTransform: "none", backgroundColor: primaryBlue }}
                    variant="contained"
                    startIcon={<FileDownloadOutlined />}
                  >
                    <a href={`/dayschedule/${selectedCity?.value}/${selectedDate}`} rel="noreferrer">
                      Download Jadwal Hari ini
                    </a>
                  </Button>
                  <br />
                  <Button
                    sx={{ backgroundColor: primaryBlue, borderRadius: "24px", mb: "24px", textTransform: "none" }}
                    variant="contained"
                    startIcon={<FileDownloadOutlined />}
                  >
                    <a href={`/monthschedule/${selectedCity?.value}`} rel="noreferrer">
                      Download Jadwal Bulan ini
                    </a>
                  </Button>
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ textAlign: "center", display: "flex", justifyContent: "center" }}>
              <Grid container sx={{ justifyContent: "center", alignItems: "center", alignContent: "space-between", px: "24px", rowGap: "6px" }}>
                {dailySchedule &&
                  SCHEDULE_OPTIONS.map((item: string, index: number) => {
                    let itemHelper = item as keyof TSchedule;
                    let blueBackground = ["subuh", "zuhur", "isya"];

                    return (
                      <>
                        <Grid item xs={4} sx={{ color: primaryBlue, display: "flex", justifyContent: matches500 ? "center" : "flex-end" }}>
                          {dailySchedule[itemHelper]}
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
                          <Box
                            sx={{
                              width: "10px",
                              height: "10px",
                              backgroundColor: "transparent",
                              borderColor: index % 2 === 0 ? primaryBlue : primaryRed,
                              borderWidth: "1px",
                              borderRadius: "999px",
                            }}
                          ></Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              width: "108px",
                              height: "30px",
                              py: "4px",
                              background:
                                index % 2 === 0
                                  ? `linear-gradient(to bottom, ${secondaryBlue}, ${primaryBlue})`
                                  : `linear-gradient(to bottom, ${secondaryRed}, ${primaryRed})`,
                              borderRadius: "999px",
                              justifyContent: "center",
                              color: "white",
                            }}
                          >
                            {item.toUpperCase()}
                          </Box>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid xs={12} sx={{ alignItems: "center", justifyContent: "center", textAlign: "center", my: "24px" }}>
              {matches600 ? (
                <>
                  <Box sx={{ color: primaryBlue, fontSize: "12px", mb: "48px" }}>
                    <span style={{ color: primaryRed }}>Catatan:</span> Penentuan awal dan akhir Ramadan mengikuti keputusan Pemerintah melalui Sidang Isbat Kementerian Agama RI
                  </Box>
                  <Button
                    sx={{ backgroundColor: primaryBlue, borderRadius: "24px", mb: "24px", textTransform: "none" }}
                    variant="contained"
                    startIcon={<FileDownloadOutlined />}
                  >
                    <a href={`/dayschedule/${selectedCity?.value}/${selectedDate}`} target="_blank" rel="noreferrer">
                      Download Jadwal Hari ini
                    </a>
                  </Button>
                  <br />
                  <Button
                    sx={{ backgroundColor: primaryBlue, borderRadius: "24px", mb: "24px", textTransform: "none" }}
                    variant="contained"
                    startIcon={<FileDownloadOutlined />}
                  >
                    <a href={`/monthschedule/${selectedCity?.value}`} target="_blank" rel="noreferrer">
                      Download Jadwal Bulan ini
                    </a>
                  </Button>
                </>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container sx={{ textAlign: "center", mt: "48px", mb: "6px", rowGap: "6px" }}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Button
                    disabled={!selectedCity}
                    sx={{ borderRadius: "999px", textTransform: "none", backgroundColor: primaryBlue }}
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                      setIsAll(true);
                    }}
                  >
                    Tambah Jadwal Bulanan ke Kalender
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Button
                    disabled={!selectedCity}
                    sx={{ borderRadius: "999px", textTransform: "none", backgroundColor: primaryBlue }}
                    variant="contained"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Tambah Jadwal Harian ke Kalender
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Grid item xs={12} sx={{ mt: "64px", textAlign: "center" }}>
          {/* <Box sx={{ fontSize: "24px", fontWeight: 700, color: primaryBlue, textAlign: "center", mb: "24px" }}>Buka Rekening Sekarang!</Box> */}
          <Grid container sx={{ alignItems: "center", justifyContent: "center" }}>
            <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-start", mr: "6px" }}>
              <Box>
                <img src="/assets/new-design/scan2.png" alt="moon" width="100%" height="100%" />
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box>
                <img src="/assets/design/btn-app.png" alt="moon" width="160px" height="120px" />
              </Box>
            </Grid>
            <Grid item xs={2} sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Box>
                <a href="https://play.google.com/store/apps/details?id=id.co.btn.mobilebanking.android&hl=en" target="_blank" rel="noreferrer">
                  <img src="/assets/design/googleplay.png" alt="moon" width="160px" height="160px" />
                </a>
              </Box>
              <Box>
                <a href="https://apps.apple.com/id/app/btn-mobile/id6443466424?l=id" target="_blank" rel="noreferrer">
                  <img src="/assets/design/app-store.png" alt="moon" width="160px" height="160px" />
                </a>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddToCalendarModal setIsAll={setIsAll} isAll={isAll} selectedSchedule={dailySchedule ?? undefined} open={open} setOpen={setOpen} />
    </Box>
  );
}

export default observer(ScheduleDetail);
