import { useState } from "react";
import { TailwindProvider } from "../../app/component/TailwindProvider"
import { IconButton, Stack } from "@mui/material";
import { Delete, Settings } from "@mui/icons-material";
import { ComponentWrapper } from "../template/ComponentWrapper";
import { SpecialColumnItem } from "./SpecialColumnItem";

const columnClassName = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
  11: 'grid-cols-11',
  12: 'grid-cols-12',
}

interface SpecialColumnProps {
  space?: number;
  padding?: 0;
}

export const SpecialColumn = ({ space, padding }: SpecialColumnProps) => {
  const [column, setColumn] = useState<keyof typeof columnClassName>(1)
  
  return (
    <TailwindProvider>
      <div className={`grid ${columnClassName[column]}`} style={{ gap: space+'px', padding: padding+'px' }}>
        {new Array(column).fill(null).map((item, index) => (
          <SpecialColumnItem>
            haha
          </SpecialColumnItem>
        ))}
      </div>
    </TailwindProvider>
  )
}

SpecialColumn.displayName = 'Column'