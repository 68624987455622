import PrivacyAndTerms from "../component/home/PrivacyAndTerms";
import { PRIVACY_POLICY } from "../../app/config/enum";

export default function PrivacyPolicy() {
  return (
    <PrivacyAndTerms
      datas={PRIVACY_POLICY}
      title="Privacy Policy"
      mainDescription="At Jadwal Imsakiyah BTN 2024, accessible from https://dev.btnimsak24.dev.rekadia.co.id/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Jadwal Imsakiyah BTN 2024 and how we use it.If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us."
    />
  );
}
