import React, { useEffect } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import { useParams } from "react-router-dom";
import ScheduleDataGridNewDesign from "../../ScheduleDataGridNewDesign";

function MonthlyScheduleDetailNewDesgin() {
  const {
    scheduleStore: { allSchedule, getAllSchedule, loadingGetAllSchedule },
  } = useStore();
  const { cityId } = useParams();

  useEffect(() => {
    if (!cityId) return;

    getAllSchedule(cityId);
  }, []);

  return (
    <Box>
      {allSchedule && !loadingGetAllSchedule ? (
        <>
          <Grid sx={{ width: "80%", my: "12px" }} container>
            <Grid item xs={12} sx={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ScheduleDataGridNewDesign />
            </Grid>
          </Grid>
        </>
      ) : (
        <Box sx={{ my: "48px" }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default observer(MonthlyScheduleDetailNewDesgin);
