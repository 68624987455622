import { Box } from "@mui/material";
import { secondaryBlue } from "../../../utils/color";

export default function Footer() {
  return (
    <Box
      sx={{
        height: "60px",
        alignItems: "center",
        display: "flex",
        width: "100%",
        fontWeight: "bold",
        justifyContent: "center",
        backgroundColor: "#003262",
        // background: ({ palette }) => `linear-gradient(to bottom, ${palette.primary.main}, ${secondaryBlue})`,
        color: "white",
      }}
    >
      <p>&copy; Copyright 2024 | Bank Tabungan Negara</p>
    </Box>
  );
}
