import { Grid, Box } from "@mui/material";

export default function BTNMediaNewDesign() {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        rowGap: "12px",
        backgroundAttachment: "inherit",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundImage: `url('/assets/new-design/bottom.png')`,
      }}
    >
      <Grid item xs={12} sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column", pl: "24px", pb: "2px" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <img src="/assets/new-design/Supergraphic.png" alt="moon" width="100%" height="100%" />
        </Box>
      </Grid>
    </Grid>
  );
}
