import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { FileUploadOutlined } from "@mui/icons-material";
import { TCity } from "../../app/models/city";
import ScheduleDataGrid from "../home/ScheduleDataGrid";

function PagePage() {
  const {
    cityStore: { cities, getCityList },
    scheduleStore: { allSchedule, clearAllSchedule, getAllSchedule, uploadExcelProduct, loadingUploadSchedule },
  } = useStore();
  const [selectedCity, setSelectedCity] = useState<TCity | null>(null);

  useEffect(() => {
    getCityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearAllSchedule();
    if (selectedCity) {
      getAllSchedule(selectedCity?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCity]);

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, m: "24px" }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={cities.map((item) => ({
            label: item.text,
            value: item.value,
          }))}
          onChange={(e, v: any) => setSelectedCity(v)}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Select City" />}
        />
        <Button
          color="primary"
          variant="contained"
          disabled={loadingUploadSchedule || (allSchedule !== null && allSchedule.length !== 0)}
          sx={{ whiteSpace: "nowrap" }}
          component="label"
          startIcon={<FileUploadOutlined />}
        >
          Upload Excel
          <input
            type="file"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            hidden
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (!selectedCity) return;
              if (e.target.files && e.target.files.length > 0) {
                uploadExcelProduct(e.target.files[0], selectedCity.value).then(() => {
                  if (!selectedCity) return;

                  getAllSchedule(selectedCity?.value);
                });
              }
            }}
            name="postScreenshotFile"
          />
        </Button>
      </Box>
      <ScheduleDataGrid />
    </Box>
  );
}

export default observer(PagePage);
