import { Box } from "@mui/material";
import { primaryBlue, primaryRed } from "../../../utils/color";
import { useContext, useEffect, useMemo, useState } from "react";
import { DataContext } from "../../../App";
import { useStore } from "../../../app/stores/store";
import { useParams } from "react-router-dom";

export default function ExportHeaderNewDesign() {
  const { matches500, matches300, mH800 } = useContext(DataContext);
  const [selectedCityName, setSelectedCityName] = useState("");

  const {
    cityStore: { getAllCity },
  } = useStore();

  const { cityId } = useParams();

  const fontSizeResponsive = useMemo(() => {
    if (matches300) return "11px";
    if (matches500) return "14px";
    return "18px";
  }, [matches300, matches500]);

  const fontSizeDesc = useMemo(() => {
    if (matches300) return "9px";
    if (matches500) return "10px";
    return "11px";
  }, [matches300, matches500]);

  const paddingHeader = useMemo(() => {
    if (mH800) return "0px";
    return "60px";
  }, [mH800]);

  useEffect(() => {
    if (!cityId) return;
    getAllCity().then((res) => {
      let temp = res.find((item) => item?.value === cityId)?.text;
      setSelectedCityName(temp ?? "");
    });
  }, [cityId, getAllCity]);

  return (
    <>
      <img style={{ objectFit: "cover" }} src="/assets/new-design/top.png" alt="bumn" width="100%" height="140px" />
      <Box sx={{ mt: "-45px", pt: paddingHeader }}>
        <Box sx={{ color: primaryBlue, fontWeight: "bold" }}>
          <Box sx={{ fontSize: fontSizeResponsive, lineHeight: "18px", mb: "4px" }}>
            TRANSFORMASI DIRI UNTUK <br /> RAIH KEBERKAHAN DI BULAN SUCI
          </Box>
          <Box sx={{ fontSize: fontSizeDesc, lineHeight: "12px" }}>
            Manfaatkan momentum Ramadan sebagai proses refleksi <br /> menuju pribadi yang mampu mengamalkan nilai-nilai kebaikan bagi sesama.
          </Box>
        </Box>

        <Box
          sx={{
            px: "8px",
            fontWeight: "bold",
            fontSize: "12px",
            mt: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            pb: "6px",
            width: "fit-content",
            color: "white",
            backgroundColor: primaryRed,
            mx: "24px",
            borderWidth: "1px",
            borderRadius: "6px",
            borderColor: "black",
          }}
        >
          Jadwal Imsakiyah Ramadan 1445 H Wilayah {selectedCityName} dan Sekitarnya
        </Box>
      </Box>
    </>
  );
}
