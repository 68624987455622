import { Grid, Box } from "@mui/material";
import { useContext, useMemo } from "react";
import { DataContext } from "../../../App";

export default function BTNMediaNewDesignDaily() {
  const { matches600, matches900, matches1200, matches700, matches800 } = useContext(DataContext);

  const positionContainer = useMemo(() => {
    if (matches1200) return "absolute";
    return "";
  }, [matches1200]);

  const bottomContainer = useMemo(() => {
    if (matches1200) return "0px";
    return "";
  }, [matches1200]);

  const widthContainer = useMemo(() => {
    if (matches600) return "100%";
    if (matches700) return "80%";
    if (matches800) return "70%";
    if (matches900) return "60%";
    if (matches1200) return "50%";
    return "100%";
  }, [matches1200, matches600, matches700, matches800, matches900]);

  return (
    <Grid
      container
      sx={{
        width: widthContainer,
        position: positionContainer,
        bottom: bottomContainer,
        display: "flex",
        rowGap: "12px",
        backgroundAttachment: "inherit",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        backgroundImage: `url('/assets/new-design/bottom.png')`,
      }}
    >
      <Grid item xs={12} sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", pl: "24px", pb: "2px" }}>
          <img src="/assets/new-design/Supergraphic.png" alt="moon" width="100%" height="100%" />
        </Box>
      </Grid>
    </Grid>
  );
}
