import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { nanoid } from "nanoid";
import { ComponentLayout } from "../template/type";
import { getCompoentOptions } from "../../utils/getComponentOptions";

interface SelectTemplateProps {
  callback: (value: ComponentLayout) => void;
}

export const SelectTemplate = ({ callback }: SelectTemplateProps) => {
  const selectComponent = (event: SelectChangeEvent) => {
    const value: ComponentLayout = {
      componentId: nanoid(),
      name: event.target.value,
      displayName: options.find(predict => predict.value === event.target.value)?.label
    }
    
    callback(value)
  };
  
  const options = getCompoentOptions()
  
  return (
    <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
        <Select
          labelId="template"
          id="template"
          label="Select Template"
          onChange={selectComponent}
        >
          {options.map((option, index) => (
            <MenuItem value={option.value} key={index}>{option.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
  )
}