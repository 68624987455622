import { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { useStore } from "../../app/stores/store";
import { TSchedule } from "../../app/models/schedule";
import { observer } from "mobx-react-lite";
import { Box, Grid } from "@mui/material";
import { DataContext } from "../../App";
import { primaryBlue } from "../../utils/color";

type TScheduleDataGridProps = {
  setSelectedSchedule?: Dispatch<SetStateAction<TSchedule | undefined>>;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  isPrint?: boolean;
};

function ScheduleDataGridNewDesign({ isPrint = false, setSelectedSchedule, setOpen }: TScheduleDataGridProps) {
  const { matches500, matches600, matches900, matches1200 } = useContext(DataContext);
  const {
    scheduleStore: { allSchedule },
  } = useStore();

  const fontSizeResponsive = useMemo(() => {
    if (matches600) return "7px";
    if (matches900) return "9px";
    if (matches1200) return "10px";
    return "10px";
  }, [matches1200, matches600, matches900]);

  const responsiveSX = useMemo(() => {
    if (matches500) return 2;
    return 1.1;
  }, [matches500]);

  const addedData = useMemo(() => {
    if (matches500) return 2;
    return 1;
  }, [matches500]);

  const Header = () => {
    return (
      <Grid
        item
        xs={responsiveSX}
        sx={{
          textAlign: "center",
          alignItems: "center",
          backgroundColor: primaryBlue,
          borderWidth: "1px",
          borderRadius: "6px",
          borderColor: "black",
        }}
      >
        <Box
          sx={{
            flexDirection: "column",
            textAlign: "center",
            color: "white",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              color: primaryBlue,
              fontWeight: "bold",
              borderTopLeftRadius: "6px",
              borderTopRightRadius: "6px",
              fontSize: "11px",
              pb: "6px",
              height: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            Imsak
          </Box>
          <Box sx={{ pb: "6px", borderTop: "1px solid black" }}>Subuh</Box>
          <Box sx={{ pb: "6px" }}>Terbit</Box>
          <Box sx={{ pb: "6px" }}>Duha</Box>
          <Box sx={{ pb: "6px" }}>Ashar</Box>
          <Box sx={{ pb: "6px", borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "white", color: primaryBlue }}>
            Maghrib
          </Box>
          <Box sx={{ pb: "6px" }}>Isya</Box>
        </Box>
      </Grid>
    );
  };

  return (
    <Box sx={{ alignItems: "center", width: "100%" }}>
      <Grid
        sx={{
          fontSize: fontSizeResponsive,
          textAlign: "center",
          rowGap: "4px",
          columnGap: "4px",
          color: primaryBlue,
          justifyContent: "center",
        }}
        container
      >
        {allSchedule?.map((item: TSchedule, index: number) => (
          <>
            {index % (Math.floor(12 / responsiveSX) - addedData) === 0 ? <Header /> : ""}
            <Grid
              item
              xs={responsiveSX}
              sx={{
                textAlign: "center",
                alignItems: "center",
                rowGap: "4px",
                backgroundColor: primaryBlue,
                borderWidth: "1px",
                borderRadius: "6px",
                borderColor: "black",
              }}
            >
              <Box
                sx={{
                  flexDirection: "column",
                  textAlign: "center",
                  color: "white",
                }}
              >
                <Box sx={{ pb: "6px", fontWeight: "bold", fontSize: "14px", color: "white" }}>{index + 1}</Box>
                <Box sx={{ pb: "6px", borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "white", color: primaryBlue }}>
                  {item.imsak}
                </Box>
                <Box sx={{ pb: "6px" }}>{item.subuh}</Box>
                <Box sx={{ pb: "6px" }}>{item.terbit}</Box>
                <Box sx={{ pb: "6px" }}>{item.duha}</Box>
                <Box sx={{ pb: "6px" }}>{item.ashar}</Box>
                <Box sx={{ pb: "6px", borderBottom: "1px solid black", borderTop: "1px solid black", backgroundColor: "white", color: primaryBlue }}>
                  {item.magrib}
                </Box>
                <Box sx={{ pb: "6px" }}>{item.isya}</Box>
              </Box>
            </Grid>
          </>
        ))}
      </Grid>
    </Box>
  );
}

export default observer(ScheduleDataGridNewDesign);
