import { Box, Button, Checkbox, FormControlLabel, Grid, Modal } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { GOOGLE_SCOPES, SCHEDULE_OPTIONS } from "../../../app/config/enum";
import { useGoogleLogin } from "@react-oauth/google";
import { TSchedule } from "../../../app/models/schedule";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import axios from "axios";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

type TAddToCalendarModal = {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  selectedSchedule: TSchedule | undefined;
  setIsAll: Dispatch<SetStateAction<boolean>>;
  isAll: boolean;
};

function AddToCalendarModal({ selectedSchedule, open, setOpen, isAll = false, setIsAll }: TAddToCalendarModal) {
  const {
    scheduleStore: { allSchedule },
  } = useStore();
  const [user, setUser] = useState<any>();
  const [scheduleItems, setScheduleItems] = useState<any>(
    SCHEDULE_OPTIONS.reduce((obj, item) => {
      return {
        ...obj,
        [item]: false,
      };
    }, {})
  );

  const handleClose = () => {
    setIsAll(false);
    setOpen(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, item: string) => {
    setScheduleItems((prev: any) => ({ ...prev, [item]: event.target.checked }));
  };

  async function createCalendarEvent(selectedTime: string, startDateMonthly?: string, refferenceMonthly?: string) {
    if (!selectedSchedule) return;

    let selectedTimeHelper = selectedTime as keyof TSchedule;

    const dailyEvent = {
      summary: `Saatnya ${selectedTime.toUpperCase()} untuk ${selectedSchedule.cityName} dan Sekitarnya`,
      // summary: `BTN SCHEDULE (${selectedSchedule.cityName}) - ${selectedTime.toUpperCase()}`,
      description: "Jadwal Imsakiyah Ramadan 1445H - Bank BTN",
      start: {
        dateTime: `${selectedSchedule?.date.slice(0, 11)}${selectedSchedule?.[selectedTimeHelper]}:00`,
        timeZone: "Asia/Jakarta",
      },
      end: {
        dateTime: `${selectedSchedule?.date.slice(0, 11)}${selectedSchedule?.[selectedTimeHelper]}:00`,
        timeZone: "Asia/Jakarta",
      },
      reminders: {
        useDefault: false,
        overrides: [
          {
            method: "popup",
            minutes: "0",
          },
        ],
      },
    };

    const monthlyEvent = {
      summary: `Saatnya ${selectedTime.toUpperCase()} untuk ${selectedSchedule.cityName} dan Sekitarnya`,
      description: "Jadwal Imsakiyah Ramadan 1445H - Bank BTN",
      start: {
        dateTime: startDateMonthly,
        timeZone: "Asia/Jakarta",
      },
      end: {
        dateTime: startDateMonthly,
        timeZone: "Asia/Jakarta",
      },
      reminders: {
        useDefault: false,
        overrides: [
          {
            method: "popup",
            minutes: "0",
          },
        ],
      },
      recurrence: [refferenceMonthly],
    };

    await fetch("https://www.googleapis.com/calendar/v3/calendars/primary/events", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + user.access_token,
      },
      body: JSON.stringify(isAll ? monthlyEvent : dailyEvent),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
      })
      .finally(() => handleClose());
  }

  const login = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      setUser((prevState: any) => {
        if (codeResponse !== prevState) return codeResponse;
      });
    },
    onError: (error) => console.log("Login Failed:", error),
    scope: GOOGLE_SCOPES,
  });

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then(() => {
          if (isAll) {
            SCHEDULE_OPTIONS.map((item, index) => {
              setTimeout(() => {
                if (scheduleItems[item]) {
                  let checkPointstartDate = 0;
                  let itemHelper = item as keyof TSchedule;
                  allSchedule?.map((schedule: TSchedule, scheduleIndex: number) => {
                    let startDate = "";
                    let refference = "";
                    if (scheduleIndex === 28 || schedule[itemHelper] !== allSchedule[scheduleIndex + 1][itemHelper]) {
                      startDate = `${allSchedule[checkPointstartDate]?.date.slice(0, 11)}${allSchedule[checkPointstartDate]?.[itemHelper]}:00`;
                      checkPointstartDate = scheduleIndex + 1;
                      refference = `RRULE:FREQ=DAILY;UNTIL=${schedule.date.slice(0, 4)}${schedule.date.slice(5, 7)}${schedule.date.slice(
                        8,
                        10
                      )}T170000Z`;
                      createCalendarEvent(item, startDate, refference);
                    }
                  });
                }
              }, 1000 * index);
            });
          } else {
            SCHEDULE_OPTIONS.map((item) => {
              if (scheduleItems[item]) createCalendarEvent(item);
            });
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!open) {
      setScheduleItems(
        SCHEDULE_OPTIONS.reduce((obj, item) => {
          return {
            ...obj,
            [item]: false,
          };
        }, {})
      );
    }
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Grid container sx={{ justifyContent: "center" }}>
          {SCHEDULE_OPTIONS.map((item) => (
            <Grid item xs={6} sx={{ pl: "36px", textTransform: "capitalize" }}>
              <FormControlLabel control={<Checkbox onChange={(e) => handleChange(e, item)} />} name={item} label={item} />
            </Grid>
          ))}
          <Button sx={{ mt: "12px", textTransform: "none" }} color="primary" variant="contained" onClick={() => login()}>
            Add to Calendar
          </Button>
        </Grid>
      </Box>
    </Modal>
  );
}

export default observer(AddToCalendarModal);
