import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { FAVOURITE_CITIES, ONE_DAY_BEFORE_RAMADHAN, RAMADHAN_DAY, RAMADHAN_FIRST_DAY } from "../../app/config/enum";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import { addDay } from "../../utils/utils";
import ScheduleDetail from "./ScheduleDetail";
import Divider from "@mui/material/Divider";
import { DataContext } from "../../App";
import { backgroundMain, primaryBlue, secondaryBlue } from "../../utils/color";
import Header from "../component/home/Header";

export type TSelectedCity = {
  label: string;
  value: string;
};

export default function Schedule() {
  const { matches1023, matches600, matches984 } = useContext(DataContext);

  const {
    cityStore: { cities, getCityList },
    scheduleStore: { getDailySchedule, clearAllSchedule, getAllSchedule },
  } = useStore();
  const [selectedCity, setSelectedCity] = useState<TSelectedCity | null>(null);
  const [selectedDate, setSelectedDate] = useState<string>(RAMADHAN_FIRST_DAY);

  const widthContainer = useMemo(() => {
    if (matches600) return "100%";
    if (matches984) return "90%";
    if (matches1023) return "80%";
    return "60%";
  }, [matches1023, matches600, matches984]);

  const onCityChange = (fCity: string) => {
    let temp = cities.filter((city) => city.text === fCity);
    setSelectedCity({ label: temp[0].text, value: temp[0].value });
  };

  const ramadhanDateDisplay = () => {
    let content = [];
    for (let day = 1; day <= RAMADHAN_DAY; day++) {
      const date = new Date(ONE_DAY_BEFORE_RAMADHAN);
      const result = addDay(date, day);
      const resultConverted = result.toISOString().split("T")[0];

      content.push(
        <Grid
          onClick={() => setSelectedDate(resultConverted)}
          className="ramadhan-date-list"
          sx={
            selectedDate === resultConverted
              ? {
                  borderRadius: "9999px",
                  backgroundColor: ({ palette }) => palette.primary.main,
                  color: "white",
                  cursor: "pointer",
                  py: "18px",
                }
              : {
                  color: ({ palette }) => palette.primary.main,
                  cursor: "pointer",
                  borderRadius: "9999px",
                  py: "18px",
                }
          }
          item
          xs={2.2}
          sm={2}
          md={1.8}
          lg={1.6}
          xl={1.4}
        >
          <Box sx={{ fontWeight: 600, fontSize: "13px" }}>{format(result, "dd MMM yyyy")}</Box>
          <Box sx={{ fontWeight: 800, fontSize: "18px" }}>{day}</Box>
          <Box sx={{ fontWeight: 600, fontSize: "13px" }}>Ramadan</Box>
        </Grid>
      );
    }

    return content;
  };

  const getCity = async () => {
    const res = await getCityList();
    if (!res) return;

    setSelectedCity({ value: res?.value, label: res?.text });

    return res;
  };

  useEffect(() => {
    getCity().then((res) => getDailySchedule(res?.value || "", selectedDate));
  }, []);

  useEffect(() => {
    if (!selectedCity) return;
    clearAllSchedule();

    getAllSchedule(selectedCity?.value);
  }, [clearAllSchedule, getAllSchedule, selectedCity]);

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
      <Header />
      <Grid
        sx={{
          backgroundColor: backgroundMain,
          backgroundAttachment: "inherit",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundImage: `url('/assets/new-design/bg.png')`,
          // boxShadow: "0 10px 8px rgb(0 0 0 / 0.04), 0 4px 3px rgb(0 0 0 / 0.1)",
          width: matches1023 ? "100%" : "80%",
          py: "48px",
          textAlign: "center",
          px: "24px",
          zIndex: 999,
        }}
        container
      >
        <Grid item xs={12}>
          <span style={{ color: primaryBlue, fontWeight: "bold", fontSize: "1.25rem" }}>Pilih Kota</span>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            borderRadius: "24px",
          }}
        >
          <Grid sx={{ width: widthContainer, mt: "24px", textAlign: "center", rowGap: "6px", justifyContent: "center" }} container>
            {FAVOURITE_CITIES.map((fCity) => (
              <Grid item xs={4} sm={3} md={2.3} lg={2.3} xl={2.3}>
                <Box
                  onClick={() => onCityChange(fCity)}
                  sx={
                    selectedCity?.label === fCity
                      ? {
                          mx: "2px",
                          background: ({ palette }) => `linear-gradient(to bottom, ${secondaryBlue}, ${palette.primary.main})`,
                          color: "white",
                          cursor: "pointer",
                          borderRadius: 24,
                          py: "6px",
                        }
                      : {
                          mx: "2px",
                          backgroundColor: "white",
                          color: primaryBlue,
                          cursor: "pointer",
                          borderRadius: 24,
                          py: "6px",
                        }
                  }
                >
                  {fCity}
                </Box>
              </Grid>
            ))}
            <Grid item xs={12} sx={{ mt: "24px" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={cities.map((item) => ({
                  label: item.text,
                  value: item.value,
                }))}
                sx={{ backgroundColor: "white", borderColor: "white", borderRadius: "6px" }}
                value={selectedCity}
                onChange={(e, v: any) => setSelectedCity(v)}
                renderInput={(params) => <TextField {...params} label="Pilih Kota" />}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: "36px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
          <Grid sx={{ width: widthContainer, textAlign: "center", gap: "6px", justifyContent: "center" }} container>
            {ramadhanDateDisplay()}
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ color: primaryBlue }} />
      {/* <hr style={{ height: "1px", backgroundColor: primaryBlue, border: "none" }} /> */}
      <ScheduleDetail selectedCity={selectedCity} selectedDate={selectedDate} />
    </Box>
  );
}
