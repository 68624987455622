import { Box, TextField } from "@mui/material"
import { SelectTemplate } from "./SelectTemplate"

export const PageFormPage = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <TextField label="Title" required fullWidth />
      <SelectTemplate callback={() => {}} />
    </Box>
  )
}