import React, { useState } from "react";
import { Typography, Box, Stack, ButtonBase, Collapse } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Footer from "./Footer";
import { primaryBlue, privacyPolicy } from "../../../utils/color";

type TDataProps = {
  title: string;
  description: string;
};

type TPrivacyAndTerms = {
  datas: TDataProps[];
  title: string;
  mainDescription: string;
};

export default function PrivacyAndTerms({ datas, title, mainDescription }: TPrivacyAndTerms) {
  const [isOpenId, setIsOpenId] = useState<string>();

  return (
    <>
      <Box sx={{ p: "24px", color: privacyPolicy }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: "24px", flexDirection: "column" }}>
          <Typography sx={{ fontWeight: "bold" }} variant="h5" color={primaryBlue}>
            {title}
          </Typography>
          <Box>Bank BTN | JADWAL IMSAKIYAH RAMADHAN 1445H</Box>
        </Box>
        <hr />
        <Box sx={{ my: "24px" }}>{mainDescription}</Box>
        {datas.map((data) => (
          <>
            <Stack
              py={"20px"}
              sx={{
                cursor: "pointer",
                width: "100%",
                justifyContent: "flex-start",
              }}
              component={ButtonBase}
              onClick={() => setIsOpenId((prev) => (prev === data.title ? undefined : data.title))}
            >
              <Stack flex={1} direction="row" spacing={"16px"} justifyContent="space-between" pr={4} sx={{ width: "100%" }}>
                <Stack direction="row" alignItems="center" spacing={"16px"}>
                  {isOpenId === data.title ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  <Typography fontWeight={700} color="black">
                    {data.title}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <hr />
            <Collapse in={isOpenId === data.title} timeout="auto" unmountOnExit>
              {data.description}
            </Collapse>
          </>
        ))}
      </Box>
      <Footer />
    </>
  );
}
