import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { TCity } from "../models/city";
import { serialize } from "object-to-formdata";
import { TSchedule } from "../models/schedule";

export default class ScheduleStore {
  cities: TCity[] = [];
  allSchedule: TSchedule[] | null = null;
  dailySchedule: TSchedule | null = null;
  loadingUploadSchedule = false;
  loadingGetAllSchedule = false;
  loadingGetDailySchedule = false;

  constructor() {
    makeAutoObservable(this);
  }

  uploadExcelProduct = async (file: File, cityId: string) => {
    this.loadingUploadSchedule = true;

    try {
      const data = { file: file };
      const formData = serialize(data, { indices: true, dotsForObjectNotation: true });
      const res = await agent.Schedule.uploadScheduleByExcel(formData, cityId);
      console.log("res", res);
      // runInAction(() => (this.previewDataExcel = res));
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => (this.loadingUploadSchedule = false));
    }
  };

  getAllSchedule = async (cityId: string) => {
    this.loadingGetAllSchedule = true;
    try {
      const res = await agent.Schedule.getAllSchedule(cityId);
      let newResponse = res.map((item) => ({
        ...item,
        identifier: Number(item.dateHijriyah.slice(0, 2)),
      }));

      runInAction(() => (this.allSchedule = newResponse.sort((a, b) => a.identifier - b.identifier)));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGetAllSchedule = false));
    }
  };

  clearAllSchedule = () => {
    this.allSchedule = null;
  };

  getDailySchedule = async (cityId: string, date: string) => {
    this.loadingGetDailySchedule = true;
    try {
      const res = await agent.Schedule.getDailySchedule(cityId, date);

      runInAction(() => (this.dailySchedule = res[0]));
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loadingGetDailySchedule = false));
    }
  };
}
