import { createContext, useContext } from "react";
import AccountStore from "./accountStore";
import CommonStore from "./commonStore";
import DialogStore from "./helper/dialogStore";
import ExampleStore from "./exampleStore";
import ModalStore from "./helper/modalStore";
import SnackbarStore from "./helper/snackbarStore";
import UserStore from "./userStore";
import CityStore from "./cityStore";
import ScheduleStore from "./scheduleStore";
import CounterStore from "./counterStore";

export const store = {
  exampleStore: new ExampleStore(),
  commonStore: new CommonStore(),
  accountStore: new AccountStore(),
  userStore: new UserStore(),
  snackbarStore: new SnackbarStore(),
  modalStore: new ModalStore(),
  dialogStore: new DialogStore(),
  cityStore: new CityStore(),
  scheduleStore: new ScheduleStore(),
  counterStore: new CounterStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
