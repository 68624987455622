import { makeAutoObservable, observable, runInAction } from "mobx";
import agent from "../api/agent";
import { TCity } from "../models/city";
import { DKI } from "../config/enum";

export default class CityStore {
  cities: TCity[] = [];
  loading = false;
  defaultCity: TCity | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  getCityList = async () => {
    this.loading = true;
    try {
      const res = await agent.City.getCity();
      const defaultCity = res.find((element) => element.text === DKI);

      runInAction(() => (this.cities = res));
      return defaultCity;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };

  getAllCity = async () => {
    this.loading = true;
    try {
      const res = await agent.City.getCity();

      return res;
    } catch (error) {
      throw error;
    } finally {
      runInAction(() => (this.loading = false));
    }
  };
}
