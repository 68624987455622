import { Category, Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ReactNode } from "react"

interface SpecialColumnItemProps {
  children: ReactNode;
}

export const SpecialColumnItem = ({ children }: SpecialColumnItemProps) => {
  return (
    <div style={{ backgroundColor: 'rgba(0,0,0,.05)', position: 'relative' }}>
      <IconButton size="small" sx={{ position: 'absolute', top: -10, right: -10 }} onClick={() => {}}>
        <Delete fontSize="small" />
      </IconButton>
      {children}
    </div>
  )
}

// add Category
// - Nama: article
// - slug: /article

// - Tumbuhan yang nyanya ada di indonesia
// slug: /article/rumbuhan-yang-ada-di-indonesia;

// - Pulau pulau indah di indoseia

// Ada apa
// /article/ada-apa

// what happen
// /article/what-happen