import React, { useEffect } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { SCHEDULE_OPTIONS } from "../../../../app/config/enum";
import { TSchedule } from "../../../../app/models/schedule";
import { useStore } from "../../../../app/stores/store";
import { useParams } from "react-router-dom";
import { primaryBlue, primaryRed, secondaryBlue, secondaryRed } from "../../../../utils/color";

function ScheduleDetailNewDesign() {
  const {
    scheduleStore: { dailySchedule, getDailySchedule, loadingGetDailySchedule },
  } = useStore();
  const { cityId, date } = useParams();

  useEffect(() => {
    if (!cityId || !date) return;

    getDailySchedule(cityId, date);
  }, []);

  return (
    <Box>
      <Grid sx={{ width: "80%", my: "12px" }} container>
        {loadingGetDailySchedule ? (
          <Grid xs={24} sx={{ my: "48px", textAlign: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} sx={{ color: primaryBlue, textAlign: "center" }}>
              <Box sx={{ mb: "36px" }}>
                <Box sx={{ fontSize: "18px" }}>
                  {dailySchedule?.dateHijriyah} - {format(new Date(dailySchedule?.date || "2024-03-12"), "dd MMMM yyyy", { locale: id })}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", display: "flex" }}>
              <Grid container sx={{ rowGap: "6px", justifyContent: "center", alignItems: "center", alignContent: "space-between" }}>
                {dailySchedule &&
                  SCHEDULE_OPTIONS.map((item: string, index: number) => {
                    let itemHelper = item as keyof TSchedule;
                    let blueBackground = ["subuh", "zuhur", "isya"];

                    return (
                      <>
                        <Grid
                          item
                          xs={4}
                          sx={{ color: primaryBlue, display: "flex", justifyContent: "center", fontSize: "16px", fontWeight: "bold" }}
                        >
                          {dailySchedule[itemHelper]}
                        </Grid>
                        <Grid item xs={4} sx={{ display: "flex", justifyContent: "center" }}>
                          <Box
                            sx={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              width: "10px",
                              height: "10px",
                              backgroundColor: "transparent",
                              borderColor: index % 2 === 0 ? primaryBlue : primaryRed,
                              borderWidth: "1px",
                              borderRadius: "999px",
                            }}
                          ></Box>
                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: "-webkit-center", alignItems: "center" }}>
                          <Box
                            sx={{
                              fontSize: "12px",
                              fontWeight: "bold",
                              pt: "6px",
                              pb: "12px",
                              maxWidth: "90px",
                              background:
                                index % 2 === 0
                                  ? `linear-gradient(to bottom, ${secondaryBlue}, ${primaryBlue})`
                                  : `linear-gradient(to bottom, ${secondaryRed}, ${primaryRed})`,
                              borderRadius: "999px",
                              color: "white",
                            }}
                          >
                            {item.toUpperCase()}
                          </Box>
                        </Grid>
                      </>
                    );
                  })}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default observer(ScheduleDetailNewDesign);
