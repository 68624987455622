import { Box } from "@mui/material";
import MonthlySchedyuleDetail from "./MonthlyScheduleDetailNewDesgin";
import ExportFooter from "../ExportFooter";
import { useContext, useMemo } from "react";
import { DataContext } from "../../../../App";
import BTNMediaNewDesign from "../BTNMediaNewDesign";
import ExportHeaderNewDesign from "../ExportHeaderNewDesign";

export default function MonthScheduleNewDesign() {
  const { matches600, matches900, matches1200 } = useContext(DataContext);

  const widthContainer = useMemo(() => {
    if (matches600) return "100%";
    if (matches900) return "90%";
    if (matches1200) return "80%";
    return "60%";
  }, [matches1200, matches600, matches900]);

  return (
    <Box sx={{ minHeight: "100%", width: "100%", textAlign: "-webkit-center", backgroundColor: "#1E2837", mb: "56px" }}>
      <Box
        id="print"
        sx={{
          minHeight: "100vh",
          width: widthContainer,
          backgroundAttachment: "inherit",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          backgroundImage: `url('/assets/new-design/bg.png')`,
        }}
      >
        <ExportHeaderNewDesign />
        <MonthlySchedyuleDetail />
        <BTNMediaNewDesign />
      </Box>
      <ExportFooter />
    </Box>
  );
}
